import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SiteParams } from 'src/app/api/models/site-params';
import { constants } from 'src/app/constants';

@Component({
  selector: 'app-top-section',
  templateUrl: './top-section.component.html',
  styleUrls: ['./top-section.component.sass'],
})
export class TopSectionComponent implements OnInit {
  public BREAKPOINTS = constants.breakpoints;
  public siteParams$: Observable<SiteParams>;

  constructor(
    private store: Store,
    @Inject(LOCALE_ID) public locale: string,
  ) {}

  ngOnInit(): void {
    this.siteParams$ = this.store.select(state => state.core.siteParams);
  }

  defaultCTAText(): string {
    return $localize`:@@topSectionCTA:Jetzt anmelden`;
  }
}
