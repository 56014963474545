import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { User } from 'src/app/api/models/user';
import { CoreActions } from '../core.actions';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.sass'],
})
export class MobileNavigationComponent implements OnInit, OnDestroy {
  @Select(state => state.core.mobileNavigationOpen)
  mobileNavigationOpen$: Observable<string>;
  private user$: Observable<User>;
  public isLoggedIn: boolean;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.user$ = this.store.select(state => state.user.user);
    this.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => (this.isLoggedIn = !!user));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  closeNav() {
    this.store.dispatch(new CoreActions.CloseMobileNavigation());
  }
}
