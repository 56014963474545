import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CoreActions } from '../core.actions';
import { Toast } from './toast.model';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.sass'],
})
export class ToastComponent {
  @Select(state => state.core.toasts) toasts$: Observable<Array<Toast>>;

  constructor(public store: Store) {}

  hideToast(toast: Toast) {
    this.store.dispatch(new CoreActions.HideToast(toast));
  }
}
