import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BookmarksList } from 'src/app/api/models/bookmarks-list';
import { VideoTeaser } from 'src/app/api/models/video-teaser';
import { CoreActions } from 'src/app/core/core.actions';
import { UserActions } from 'src/app/user/user.actions';
import { BookmarksActions } from '../bookmarks.actions';

export type BookmarksListChecked = BookmarksList & { checked: boolean };

@Component({
  selector: 'app-bookmarks-menu',
  templateUrl: './bookmarks-menu.component.html',
  styleUrls: ['./bookmarks-menu.component.sass'],
})
export class BookmarksMenuComponent implements OnInit {
  public static readonly MODAL_ID = 'BookmarksMenu';
  @Input() videoRef: VideoTeaser;
  @Select(state => state.bookmarks.bookmarksLists) bookmarksLists$: Observable<
    Array<BookmarksList>
  >;
  @Input() title = $localize`Video zur Liste hinzufügen`;
  public bookmarksListChecked$: Observable<BookmarksListChecked[]>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.setFavorite();

    this.store.dispatch(new BookmarksActions.FetchItemLists(this.videoRef?.id));

    this.bookmarksListChecked$ = combineLatest([
      this.bookmarksLists$,
      this.store.select(state => state.bookmarks.itemListsResponse),
    ]).pipe(
      filter(
        ([bookmarkLists, itemListsResponse]) =>
          bookmarkLists && itemListsResponse && itemListsResponse.data,
      ),
      map(([bookmarkLists, itemListsResponse]) => [
        bookmarkLists,
        itemListsResponse.data,
      ]),
      map(([bookmarkLists, itemLists]) => {
        return bookmarkLists.map(bookmarkList => {
          return {
            ...bookmarkList,
            checked: itemLists.includes(bookmarkList.id),
          };
        });
      }),
    );
  }

  private setFavorite() {
    if (this.videoRef?.is_favorite) return;
    this.videoRef.is_favorite = true;
    this.store
      .dispatch(new UserActions.SetVideoFavorite(this.videoRef.id, true))
      .subscribe({
        error: () => {
          this.videoRef.is_favorite = false;
          this.showErrorMessage();
        },
      });
  }

  showErrorMessage() {
    this.store.dispatch(
      new CoreActions.ShowToast({
        text: $localize`Speichern fehlgeschlagen.<br>Versuche es später noch einmal.`,
        icon: 'warning',
        color: 'error',
        autohide: true,
      }),
    );
  }

  closeMenu() {
    this.store.dispatch([
      new CoreActions.SetBottomsheet(null),
      new CoreActions.CloseModal(BookmarksMenuComponent.MODAL_ID),
    ]);
  }
}
