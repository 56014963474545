import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appstore-buttons',
  standalone: true,
  templateUrl: './appstore-buttons.component.html',
  styleUrls: ['./appstore-buttons.component.sass'],
  imports: [CommonModule, NgOptimizedImage],
})
export class AppstoreButtonsComponent implements OnInit {
  @Input() inMobileMenu = false;
  public playStoreLink = environment.mobileAppLinkAndroid;
  public appStoreLink = environment.mobileAppLinkIos;

  ngOnInit() {
    if (this.inMobileMenu) {
      this.playStoreLink = environment.mobileAppLinkAndroidMobileMenu;
      this.appStoreLink = environment.mobileAppLinkIosMobileMenu;
    }
  }
}
