@if (dashboard$ | async; as dashboard) {
@for (slider of dashboard.sliders; track slider.headline) {
@if($index === 1){
<app-live-section />
}
<app-teaser-slider [headline]="slider.headline" [subheadline]="slider.subheadline">
  @switch(slider.type) {
  @case('article') {
  <app-article-teaser *ngFor="let teaser of slider.teasers" [teaser]="teaser" size="small" />
  }
  @case('podcast') {
  <app-podcast-teaser *ngFor="let teaser of slider.teasers" [teaser]="teaser" size="small" />
  }
  @case('program') {
  <app-program-teaser *ngFor="let teaser of slider.teasers" [teaser]="teaser" size="small" />
  }
  @case('teacher') {
  <app-teacher-teaser *ngFor="let teaser of slider.teasers" [teaser]="teaser" size="small" />
  }
  @case('video') {
  <app-video-teaser *ngFor="let teaser of slider.teasers" [teaser]="teaser" size="small" />
  }
  }
  <app-more-teaser [type]="slider.type" [href]="slider.link" />
</app-teaser-slider>
}
} @else {
<app-ye-loading-spinner />
}