import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Footer } from 'src/app/api/models/footer';
import { SiteParams } from 'src/app/api/models/site-params';
import { constants } from 'src/app/constants';
import { NewsletterActions } from 'src/app/newsletter/newsletter.actions';
import { CoreActions } from '../core.actions';
import { validateEmail } from '../email-validator/email.validator';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass'],
})
export class FooterComponent implements OnInit {
  public BREAKPOINTS = constants.breakpoints;

  @Select(state => state.core.contentOnly) contentOnly$: Observable<boolean>;
  @Select(state => state.core.siteParams) siteParams$: Observable<SiteParams>;

  public emailForm = new UntypedFormGroup(
    {
      emailAddress: new UntypedFormControl('', [
        Validators.required,
        validateEmail,
      ]),
    },
    { updateOn: 'submit' },
  );
  public emailFormSubmitted = false;
  public footer$: Observable<Footer>;

  constructor(
    private store: Store,
    @Inject(LOCALE_ID) public locale: string,
  ) {}

  ngOnInit() {
    this.store.dispatch(new CoreActions.FetchFooter());
    this.footer$ = this.store.select(state => state.core.footer);
  }

  onCancel() {
    this.store.dispatch(
      new CoreActions.SendToGTM({
        DL_leanplum_events: [
          {
            event: 'FE Subscription cancel',
          },
        ],
        event: 'FE_leanplum',
      }),
    );
  }

  onSubmit() {
    if (!this.emailForm.valid) return;
    this.emailFormSubmitted = true;
    this.store
      .dispatch(
        new NewsletterActions.Register(
          this.emailForm.get('emailAddress').value,
        ),
      )
      .pipe(take(1))
      .subscribe(() => this.store.dispatch(new Navigate(['/newsletter'])));
  }
}
