import { Component, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { VideoTeaser } from 'src/app/api/models/video-teaser';
import { BookmarksListChecked } from '../bookmarks-menu.component';

@Component({
  selector: 'app-bookmarks-menu-list',
  templateUrl: './bookmarks-menu-list.component.html',
  styleUrls: ['./bookmarks-menu-list.component.sass'],
})
export class BookmarksMenuListComponent {
  @Input() videoRef: VideoTeaser;
  @Input() listsChecked: Array<BookmarksListChecked>;
  @Select(state => state.bookmarks.itemListsResponse.loading) loading$: Observable<boolean>;

  trackBy(_, item: BookmarksListChecked) {
    return item.id;
  }
}
