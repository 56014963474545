import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BookmarksList } from 'src/app/api/models/bookmarks-list';
import { VideoTeaser } from 'src/app/api/models/video-teaser';
import { DashboardActions } from 'src/app/dashboard/dashboard.actions';

@Component({
  selector: 'app-bookmarks-lists-page',
  templateUrl: './bookmarks-lists-page.component.html',
  styleUrls: ['./bookmarks-lists-page.component.sass'],
})
export class BookmarksListsPageComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  @Select(state => state.bookmarks.bookmarksLists)
  bookmarksLists$: Observable<Array<BookmarksList>>;
  favorites: Array<VideoTeaser>;
  favoritesVideoCount: number;
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new DashboardActions.FetchVideoTeasers('favorites'));
    this.store
      .select(state => state.dashboard.favoritesVideoTeasers)
      .pipe(takeUntil(this.destroy$))
      .subscribe(videoTeasers => (this.favorites = videoTeasers));

    this.store
      .select(state => state.dashboard.favoritesVideoCount)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        favoritesVideoCount => (this.favoritesVideoCount = favoritesVideoCount),
      );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
