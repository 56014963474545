import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BookmarkedItem } from 'src/app/api/models/bookmarked-item';
import { BookmarksListWithItems } from 'src/app/api/models/bookmarks-list-with-items';
import { BookmarksActions } from 'src/app/bookmarks/bookmarks.actions';
import { Viewport } from 'src/app/core/core.state';
import { Utility } from 'src/app/shared/utility';

@Component({
  selector: 'app-bookmarks-list-page',
  templateUrl: './bookmarks-list-page.component.html',
  styleUrls: ['./bookmarks-list-page.component.sass'],
})
export class BookmarksListPageComponent implements OnInit, OnDestroy {
  @Select(state => state.bookmarks.bookmarksList)
  bookmarksList$: Observable<BookmarksListWithItems>;
  @Select(state => state.bookmarks.bookmarksListError)
  bookmarksListError$: Observable<string>;
  public viewport$: Observable<Viewport>;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public bookmarksList: BookmarksListWithItems; // readonly
  public bookmarksItems: BookmarkedItem[];

  constructor(private store: Store) {}

  ngOnInit() {
    this.store
      .select(state => state.router.state)
      .pipe(
        takeUntil(this.destroy$),
        map(state => state?.params?.id),
        filter(id => !!id),
      )
      .subscribe(id => this.store.dispatch(new BookmarksActions.FetchList(id)));

    this.bookmarksList$
      .pipe(
        takeUntil(this.destroy$),
        filter(list => !!list),
      )
      .subscribe(list => {
        this.bookmarksList = list;
        this.bookmarksItems = structuredClone(list.items);
      });

    this.viewport$ = this.store.select(state => state.core.viewport);
  }

  onDrop(event: { previousIndex: number; currentIndex: number }) {
    const draggedItemId = this.bookmarksItems[event.previousIndex].info.id;
    Utility.moveElementInArray(
      this.bookmarksItems,
      event.previousIndex,
      event.currentIndex,
    );
    this.store.dispatch(
      new BookmarksActions.UpdateItemPosition(
        this.bookmarksList.id,
        draggedItemId,
        event.currentIndex + 1, // BE uses 1-based index
      ),
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
