<div *ngIf="(redirected$ | async) === false">
  <app-navbar *ngIf="(contentOnly$ | async) === false"></app-navbar>
  <app-mobile-navigation *ngIf="(contentOnly$ | async) === false"></app-mobile-navigation>
  <app-announcement *ngIf="(contentOnly$ | async) === false"></app-announcement>
  <div class="is-width-viewport">
    <div class="is-min-height-screensize">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer *ngIf="(contentOnly$ | async) === false"></app-footer>
  <app-sticky-cta-button *ngIf="(contentOnly$ | async) === false"></app-sticky-cta-button>
  <app-bottomsheet></app-bottomsheet>
  <app-toast></app-toast>
  <app-fullscreen-overlay></app-fullscreen-overlay>
</div>